let Freight = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    this.FreightList = function (organizationId, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate';
        if (organizationId) {
            url += `/${organizationId}`;
        }
        else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.FreightDetail = function (templateId, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate/' + templateId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    this.AddFreight = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    this.EditFreight = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 删除
     * @param {*} meridianId 
     * @param {*} success 
     * @param {*} error 
     */
    this.Remove = function (meridianId, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate/' + meridianId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Freight }

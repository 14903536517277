<template>
    <div style="height: 100%">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="$router.go(-1)" content="运费模板管理">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :inline="true">
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                <el-button type="primary" size="small" @click="addBtn(0)" plain>新增</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    
                </div>
                <el-table :data="tableData" height="calc(100% - 90px)" stripe style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="templateName" label="模板名称"></el-table-column>
                    <el-table-column prop="expressCompany" label="快递/物流" align="center"></el-table-column>
                    <el-table-column prop="firstPrice" label="首(重/件/体积)费" align="center"></el-table-column>
                    <el-table-column prop="renewalPrice" label="续(重/件/体积)费" align="center"></el-table-column>
                    <el-table-column prop="isEnable" label="状态" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.isEnable" type="success" size="small">启用</el-tag>
                            <el-tag v-if="!scope.row.isEnable" type="danger" size="small">禁用</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <span class="info optionBtn" @click="toEdit(scope.row)">编辑</span>
                            <span class="danger optionBtn" @click="toDel(scope.row)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Freight } from "../../../components/HospitalDomain/ShopMall/Freight";

export default {
    name: "Freight",
    components: {
        bread
    },
    data() {
        var freight = new Freight(this.TokenClient, this.Services.Shopping)
        return {
            FreightDomain: freight,
            Keyword: '',
            tableData: [],
            PageIndex: 1,
            PageTotal: 0,
            PageSize: 0,
            DataTotal: 0,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            searchForm: {
                keyWord: '',
            },
        }
    },
    mounted() {
        this.GetList();
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.GetList();
        },
        ChangePage() {

        },
        GetList() {
            var _this = this;
            _this.FreightDomain.FreightList(_this.organizationId, _this.searchForm.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (err) {
                    console.log(err);
                });
        },
        addBtn(i) {
            var _this = this;
            _this.$router.push(
                {
                    path: '/ShopMall/FreightAdd',
                    query: { id: i }
                }
            );
        },
        toEdit(item) {
            var _this = this;
            _this.$router.push(
                {
                    path: '/ShopMall/FreightAdd',
                    query: { id: item.id }
                }
            );
        },
        toDel(item) {
            var _this = this;
            _this.$confirm('是否删除此信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then(() => {
                _this.FreightDomain.Remove(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        _this.GetList()
                    },
                    function (err) {
                        console.log(err);
                    });
            }).catch(() => {

            });
        }
    }
}
</script>

<style scoped>
</style>
